import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import { KatSpinner } from "@amzn/katal-react";

import App from "./components/App";

import "@amzn/katal-components/styles.css";
import "./index.scss";
import AppInitWrapper from "src/components/AppInitWrapper";
import { StencilProvider } from "@amzn/stencil-react-components/dist/submodules/context";
import { I18nextProvider } from "react-i18next";
import i18n from "./panther/panther";
import { AuthOrRedirect } from "./routes/Auth";
import Root from "./routes/Root";
import { Header } from "./components/Layout/Header";
import { ROOT_URL, RVP_URL } from "./constants/url-constants";
import { GenericError } from "./components/ErrorPage/GenericError";
import PageWithIcon from "./components/Layout/PageWithIcon";
import Person from "./assets/Person2.svg";
import { AwsRumClient } from "./rum";
import { RUMContext } from "./rum/rum-context";
import RvpRoot from "./routes/RvpRoot";
import { NavigationContext } from "./rum/navigation-context";
import { AwsRum } from "aws-rum-web";

const ScheduledVNHERoute = () => {
  /**
   * We define these in the same file to ensure any routing pattern matches the expected values from useParams
   */
  const { applicationId, appointmentType, jobId } = useParams<{
    applicationId: string;
    appointmentType: string;
    jobId: string;
  }>();

  return (
    <AuthOrRedirect>
      <NavigationContext.Provider value={{ applicationId, appointmentType, jobId }}>
        <Root />
      </NavigationContext.Provider>
    </AuthOrRedirect>
  );
};

const RvpRootRoute = () => {
  return (
    <NavigationContext.Provider value={{ applicationId: "rvp", appointmentType: "NEW_HIRE_EVENT", jobId: "rvp" }}>
      <RvpRoot />
    </NavigationContext.Provider>
  );
};

/**
 * Exposed only for testing purposes, we need a memory router to do unit tests
 */
export const VNHEUIRoutes = ({ rumClient }: { rumClient?: AwsRum }) => {
  return (
    <React.Suspense fallback={<KatSpinner size="large" />}>
      <Header>
        <AppInitWrapper rumClient={rumClient}>
          <Routes>
            {/* Exclude the App from auth since root url is used on prod */}
            <Route path={`${ROOT_URL}`} element={<App />} />
            <Route path={`${ROOT_URL}/:jobId/:applicationId/:appointmentType`} element={<ScheduledVNHERoute />} />
            <Route path={`${ROOT_URL}${RVP_URL}/ingress`} element={<RvpRootRoute />} />
            <Route path="/" element={<App />} />
            <Route
              path="*"
              element={
                <PageWithIcon src={Person} data-testid="appointment-error-wrapper">
                  <GenericError error="No matching route found" />
                </PageWithIcon>
              }
            />
          </Routes>
        </AppInitWrapper>
      </Header>
    </React.Suspense>
  );
};

export const RootVNHEUI = () => {
  // Oneshot initialize the RUM client for monitoring
  const [rumClient] = useState(() => new AwsRumClient().getClient());

  return (
    <RUMContext.Provider value={rumClient}>
      <React.StrictMode>
        <StencilProvider>
          <I18nextProvider i18n={i18n}>
            <Router>
              <VNHEUIRoutes rumClient={rumClient} />
            </Router>
          </I18nextProvider>
        </StencilProvider>
      </React.StrictMode>
    </RUMContext.Provider>
  );
};

export default RootVNHEUI;
