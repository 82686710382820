import React, { useEffect } from "react";
import { ConnectQueueFragment } from "./ConnectQueueFragment";
import PageContainer from "./Layout/PageContainer";
import { ConnectWidgetData } from "./types";
import { AppointmentDetails } from "./VNHE/AppointmentDetails";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useTranslation } from "react-i18next";
import { useRum } from "src/hooks/useRum";

interface ConnectLandingProps {
  connectWidgetData?: ConnectWidgetData;
}

const ConnectLanding = ({ connectWidgetData }: ConnectLandingProps) => {
  const { recordPageView } = useRum();
  const { t } = useTranslation();

  useEffect(() => {
    recordPageView({ pageId: "active-call" });
  }, [recordPageView]);

  useEffect(() => {
    const unloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", unloadHandler);

    // Remove blocker when this component is removed from DOM
    return () => window.removeEventListener("beforeunload", unloadHandler);
  });

  return (
    <PageContainer>
      <span
        style={{
          zIndex: 1000,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MessageBanner isDismissible type={MessageBannerType.Warning} autoDismissAfter={15_000}>
          {t("VNHE-CallStarted-Warning")}
        </MessageBanner>
      </span>
      <ConnectQueueFragment connectWidgetData={connectWidgetData} />
      {connectWidgetData && <AppointmentDetails agendaPublicText={connectWidgetData.agendaPublicText} />}
    </PageContainer>
  );
};

export default ConnectLanding;
